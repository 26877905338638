.Menu {
	border: solid 1px black;
	background-color: white;
	padding: 5px;
	.item {
		cursor: pointer;
		padding: 5px 10px;
		&:hover {
			background-color: chartreuse;
		}
	}
	.divider {
		border-bottom: 1px solid black;
		font-size: 0;
	}
}