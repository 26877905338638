.TaskItem {
	display: flex;
	flex: 1 1 auto;
	background-color: black;
	color: white;
	font-size: 3.7vh;
	font-weight: bold;
	border-bottom: solid 1px white;
	&+.active {
		background-color: #008c8c;
	}
	.hour, .text {
		padding: 3px 9px;
	}
	.hour {
		width: 100px;
	}
	.text {
		flex: 1 1 auto;
	}
}